import React from 'react';

function IconButton({ icon, onClick, label }) {
  return (
    <div className="icon-button-container">
      <button className="icon-button" onClick={onClick} aria-label={label}>
        <img src={icon} alt={label} style={{ width: '22px', height: '22px' }} />
      </button>
      <span className="icon-label">{label}</span>
    </div>
  );
}

export default IconButton;