import React from 'react';
import './HelpModal.css';

function HelpModal({ isOpen, closeModal }) {
  if (!isOpen) return null;

  return (
    <div onClick={closeModal} style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000, // Ensure it's above other content
    }}>
      <div onClick={e => e.stopPropagation()} style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '800px',
        maxHeight: '80%',
        overflowY: 'auto',
      }}>
        <div className="modal-overlay">
          <div className="modal">
            <div className="container">
              <h1>Here's <span>how to </span><span>use </span><span>the </span><span>App!</span></h1>
              <ul>
                <li><strong>Ask a Question:</strong> You can type your question about the book "Start, Scale, Exit, Repeat" in the text area provided. If you're not sure what to ask, click on one of the question buttons for some common queries.</li>
                <li><strong>Submit Your Question:</strong> After typing your question, hit the "Submit" button to send it.</li>
                <li><strong>Wait for the Answer:</strong> Once you've submitted your question, the app might take a few moments to respond. </li>
                <li><strong>Listen to the Answer:</strong> If you prefer to hear the response rather than read it, click the speaker icon. You can stop the reading anytime by clicking the stop icon.</li>
                <li><strong>Explore More:</strong> Along with the answer, the app will show you curated blog posts and YouTube videos links for more information on the topic you asked.</li>
                <li><strong>Help and More:</strong> If you're stuck or need to redo your question, use the icons at the bottom for help, copying text, or asking a new question.</li>
              </ul>

              <h2>What to Expect</h2>
              <div id="features">
                <ul>
                  <li><strong>Instant Responses:</strong> Get quick replies to your queries about the book. What better way to get started?</li>
                  <li><strong>Do try:</strong> Listen to the answers "read out loud" if you don't feel like reading or "copy" the text for later read.</li>
                  <li><strong>Additional Resources:</strong> <a href="https://startup.club/category/blog/"> Read more blog posts</a></li>
                  <li><strong>Contact us:</strong> <a href="mailto:Hello@startup.club">Hello@startup.club</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpModal; // Export the HelpModal component