import React from 'react';

function QuestionButton({ children, setUserInput }) {
  const handleQuestionClick = () => {
    setUserInput(children);
  };

  return (
    <button className="question-button" onClick={handleQuestionClick} aria-label={children}>
      {children}
    </button>
  );
}

export default QuestionButton;